/**
 * Template helpers for handlebars
 *
 * @module
 */
"use strict";
var $ = require('jquery');
var _ = require('underscore');
var moment = require('moment');
var Handlebars = require("handlebars");


/**
 * basic handlebars compare function.
 *
 * @param itemA
 * @param itemB
 */
Handlebars.registerHelper('hcompare', function(itemA, itemB) {
    //return new Handlebars.SafeString(itemA == itemB); // jshint ignore:line
    return itemA == itemB; // jshint ignore:line
});

/**
 * more advanced compare function. itemA == itemB or itemC
 *
 * @param itemA
 * @param itemB
 * @param itemC
 */
Handlebars.registerHelper('hcompare', function(itemA, itemB, itemC) {
    //return new Handlebars.SafeString(itemA == itemB); // jshint ignore:line
    return (itemA == itemB) || (itemA == itemC); // jshint ignore:line
});

/**
 * Add 'active' class to current tab.
 *
 * @param expectedTab
 * @param actualTab
 */
Handlebars.registerHelper('activeClass', function(expectedTab, actualTab) {
    var returnValue = false;
    if (expectedTab == actualTab) { //jshint ignore:line
        return new Handlebars.SafeString("active");
    }
    return returnValue;
});

/**
 * update badgecount
 *
 * @param selectedCollection
 */
Handlebars.registerHelper('badgeHelper', function(selectedCollection) {
    var selectedCount = _.filter(selectedCollection,function(item) {
        return item.get('selected');
    }).length;

    //need to return "" to hide badge
    return selectedCount || "";
});

Handlebars.registerHelper('categoryCollapseHelper', function(selectedCollection, searchTerm, true_output, false_output) {
    var selectedCount = _.filter(selectedCollection,function(item) {
        return item.get('selected');
    }).length;

    return (selectedCount > 0 || searchTerm) ? true_output : false_output;
});

/**
 * Return the ordinal of a number.
 *
 * @param n
 */
Handlebars.registerHelper('ordinalHelper', function(n) {
    n = n+1;//starts at 0
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
});

/**
 * Returns the comparison based on the comparisonType.
 * Accepted values are <, <=, >, >=, =
 *
 * @param array
 * @param comparisonType
 * @param length
 */
Handlebars.registerHelper('arrayLengthCompare', function(array, comparisonType, length){
    switch (comparisonType) {
        case ">":
            return array.length > length;
        case ">=":
            return array.length >= length;
        case "=":
            return array.length == length; //jshint ignore:line
        case "<":
            return array.length < length;
        case "<=":
            return array.length <= length;
        default:
            return array.length === length;
    }
});

/**
 * Returns the comparison based on the comparisonType.
 * Accepted values are <, <=, >, >=, =
 *
 * @param value
 * @param operator
 * @param comparisonValue
 */
Handlebars.registerHelper('compareValue', function(value, operator, comparisonValue){
    value = parseInt(value);
    comparisonValue = parseInt(comparisonValue);
    switch (operator) {
        case ">":
            return value > comparisonValue;
        case ">=":
            return value >= comparisonValue;
        case "=":
            return value == comparisonValue; //jshint ignore:line
        case "<":
            return value < comparisonValue;
        case "<=":
            return value <= comparisonValue;
        default:
            return value === comparisonValue;
    }
});

/**
 * A little bit hacky - but very useful for comparisons.
 * Will remove the leading currency symbol from a string.
 *
 * E.g. "$15.65" => "15.65"
 * E.g. "£0.56" => "0.56"
 *
 * @param value
 * @param operator
 * @param comparisonValue
 */
Handlebars.registerHelper('removeCurrency', function(value){
    return value.substring(1);
});

/**
 * Strips leading zeros, and rounds figures to two decimal places, except those that have only zeros after the decimal
 * which will have the zeros removed.
 *
 * 2.00   => 2
 * 2.2    => 2.20
 * 2.23   => 2.23
 * 002.23 => 2.23
 */
Handlebars.registerHelper('twoOrZeroDecimalPlaces', function(value){
    var value = parseFloat(value);
    return value.toFixed(String(value).indexOf('.') === -1 ? 0 : 2);
});



//this is a trimmed down version of the source from https://github.com/helpers/helper-moment/blob/master/index.js
Handlebars.registerHelper('moment', function(str, pattern) {
    // if only a string is passed, assume it's a date pattern ('YYYY')
    if (typeof str === 'string' && !pattern) {
        return moment().format(str);
    }

    var date;

    date = moment(str).calendar(null,  {
        sameDay : '[Today]',
        lastDay : '[Yesterday]',
        nextDay : pattern,
        lastWeek : pattern,
        nextWeek : pattern,
        sameElse : pattern

    });

    if(date === 'Invalid date'){
        date = false;
    }

    return date;
});